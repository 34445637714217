<template>
    <v-card-subtitle class="text-small-grey my-0 py-0">
        <v-row
            v-if="showOwner"
            no-gutters
        >
            <v-col class="col-auto mr-2">
                <span class="d-inline-block">{{ $t('admin.recognitions.owner') +": " }}</span>
            </v-col>
            <v-col
                v-if="purpose"
                class="col-auto"
            >
                <span v-if="purpose.ownerData">{{ getOwner(purpose) }}</span>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="col-auto mr-2">
                <span class="d-inline-block">{{ $t('purposes.published') +": " }}</span>
            </v-col>
            <v-col
                v-if="purpose"
                class="col-auto"
            >
                <span v-if="purpose.attributes && purpose.attributes['created-at']">
                    {{ dateHelper.getDateTimeTableFormat(purpose.attributes['created-at']) }}
                </span>
                <div
                    v-else
                    :inner-html.prop="dateHelper.getDateTimeToFormatAndLocale(purpose.created_at) | highlight(search)"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="col-auto mr-2">
                <span class="d-inline-block">{{ $t('admin.common.updatedAt') +": " }}</span>
            </v-col>
            <v-col
                v-if="purpose"
                class="col-auto"
            >
                <span v-if="purpose.attributes && purpose.attributes['created-at']">{{ dateHelper.getDateTimeTableFormat(purpose.attributes['updated-at']) }}</span>
                <div
                    v-else
                    :inner-html.prop="dateHelper.getDateTimeToFormatAndLocale(purpose.updated_at) | highlight(search)"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="col-auto mr-2">
                <span class="d-inline-block">{{ $t('common.language') +": " }}</span>
            </v-col>
            <v-col
                v-if="purpose"
                class="col-auto"
            >
                <span v-if="purpose.attributes && purpose.attributes['lang']">{{ generalHelper.languageToDisplay(purpose.attributes['lang']) }}</span>
                <div
                    v-else
                    :inner-html.prop="generalHelper.languageToDisplay(purpose.lang) | highlight(search)"
                />
            </v-col>
        </v-row>
    </v-card-subtitle>
</template>

<script>
export default {
    name: 'PurposeDate',
    props: {
        purpose: {
            type: Object,
            default() {
                return null;
            }
        },
        search: {
            type: String,
            default: '',
            required: false
        },
        showOwner: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getOwner(purpose) {
            return (purpose?.ownerData?.attributes?.name ?? "") + " " + (purpose?.ownerData?.attributes?.lastname ?? "");
        }
    }
}
</script>

<style scoped>

</style>
