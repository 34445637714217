<template>
    <v-container
        fluid
        class="pt-0 mx-0 px-0"
    >
        <!--      Add purpose-->
        <div
            class="my-0 mx-0 px-0"
            style="position: relative"
        >
            <div
                style="display: flex; justify-content: space-between; flex-wrap: wrap"
            >
                <div class="mx-4 pt-3">
                    <span
                        class="text-small-grey text-gray-600"
                    >{{ $t('recognitions.userRecognized') }}</span>
                    <div
                        class="mb-0 pt-0 mt-0"
                    >
                        <span class="text-post">
                            {{ recognizedUser }}
                        </span>
                    </div>
                </div>
                <div class="mx-4 pt-3">
                    <span
                        class="text-small-grey text-gray-600"
                    >{{ $t('recognitions.typeRecognitions') }}</span>

                    <span
                        class="text-post"
                    >
                        {{ typeRecognition }}
                    </span>
                </div>
            </div>

            <v-row>
                <v-col class="px-3">
                    <!--            <v-btn
                class="mx-2 my-2"
                fab
                dark
                color="secondary"
                @click="createPurpose"
            >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>-->

                    <!--      Purposes List-->
                    <!--      :search="search"-->
                    <v-data-iterator
                        :items="dataFilter"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                    >
                        <!--                        <template v-slot:header>
                            <v-toolbar
                                v-if="$vuetify.breakpoint.mdAndUp"
                                dark
                                color="primary"
                                class="mb-1 mt-3 rounded-lg"
                            >
                                <v-text-field
                                    v-model="search"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :label="$t('common.search')"
                                />

                                <template>-->
                        <!--                        <v-spacer />
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-magnify"
                            label="Sort by"
                        />-->
                        <!--                                    <v-spacer />
                                    <v-btn-toggle
                                        v-model="sortDesc"
                                        mandatory
                                        class="ml-2"
                                    >
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="false"
                                        >
                                            <v-icon>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="true"
                                        >
                                            <v-icon>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-toolbar>
                        </template>-->

                        <template v-slot:default="props">
                            <v-row>
                                <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <recognitionSingle
                                        :item="item"
                                        :is-only-view="true"
                                        :show-recognized-person-and-type="false"
                                    />
                                </v-col>
                            </v-row>
                        </template>

                        <template
                            v-if="items && items.length"
                            v-slot:footer
                        >
                            <v-row
                                class="mt-2 ml-0 mr-0"
                                align="center"
                                justify="center"
                            >
                                <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            text
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ itemsPerPage }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(number, index) in itemsPerPageArray"
                                            :key="index"
                                            @click="updateItemsPerPage(number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer />

                                <span
                                    class="grey--text"
                                >
                                    {{ $t('common.page') + " " + page + " " + $t('common.of') + " " + numberOfPages }}
                                </span>
                                <div>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-4 mr-1"
                                        @click="formerPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-1 mr-4"
                                        @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </div>

        <confirm-dialog
            ref="confirm"
        />
    </v-container>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog'
import recognitionSingle from '@/components/recognitions/recognitionSingle'

import { mapGetters } from 'vuex'
import typesRecognitions from "@/data/typesRecognitions";
export default {
    name: 'SeeRecognitionsPersonType',
    components: { ConfirmDialog, recognitionSingle },
    data() {
        return {
            item: null,
            items: [],
            itemSelected: null,
            itemsPerPageArray: [2, 4, 8],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'attributes.updated-at',
            purposeCloud: null,
            overlayVideo: false,
            isOnlyView: true
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        typeRecognition() {
            if (this.item) {
                const type = typesRecognitions.find(t => t.id == this.item.recognitionType);
                if (type) { return type[`name_${this.getLang}`]; }
            }
            return '...';
        },
        recognizedUser() {
            return (this.item?.userData?.attributes?.name ?? "") + " " + (this.item?.userData?.attributes?.lastname ?? "");
        },
        dataFilter() {
            const search = this.search;

            if (!search) return this.items;

            return this.items.filter(child => {
                const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");

                const att = child.attributes;
                return (att.body && att.body.toUpperCase().includes(search.toUpperCase())) ||
                    (userDataFilter.toUpperCase()).includes(search.toUpperCase()) ||
                    (att['created-at'] && this.dateHelper.getDateTimeTableFormat(att['created-at']).includes(search)) ||
                    (att['updated-at'] && this.dateHelper.getDateTimeTableFormat(att['updated-at']).includes(search));
            }
            );
        },
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        }
        /* filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        } */
    },
    beforeRouteEnter(to, from, next) {
        const itemTemp = to.params?.item ?? null;
        if (itemTemp) next();
        else { next('/'); }
    },
    async mounted() {
        const itemTemp = this.$route.params?.item;
        if (itemTemp) { this.item = JSON.parse(itemTemp); }

        this.initialize();
    },
    methods: {

        initialize() {
            // console.log(items)
            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0

            const recognitions = this.item.recognitions;
            const user = this.item.userData;

            this.items = recognitions.map(recognition => {
                recognition.userData = user;
                return recognition;
            });

            // console.log('this.items ', this.items)
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },

        createPurpose() {
            this.$router.push({ name: 'RecognitionCreate' });
        }
    }
}
</script>

<!--<style >
.ql-align-right{
  text-align: right !important;
}
</style>-->
