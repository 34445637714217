<template>
    <v-card
        color="dark"
        dark
        class="rounded-xl"
    >
        <div
            v-if="showRecognizedPersonAndType"
            style="display: flex; justify-content: space-between; flex-wrap: wrap"
        >
            <div class="mx-4 pt-3">
                <span class="text-small-grey">{{ $t('recognitions.userRecognized') }}</span>
                <div
                    class="mb-0 pt-0 mt-0"
                >
                    <span class="text-post">
                        {{ recognizedUser }}
                    </span>
                </div>
            </div>
            <div class="mx-4 pt-3">
                <span
                    class="text-small-grey"
                >{{ $t('recognitions.typeRecognitions') }}</span>

                <span
                    class="text-post"
                >
                    {{ typeRecognition }}
                </span>
            </div>
        </div>

        <purposeText
            :purpose="item"
            class="mb-0 pb-0"
        >
            <span class="text-small-grey">{{ $t('recognitions.textForRecognize') }}</span>
        </purposeText>

        <div
            v-if="showLikes"
            style="display: flex; justify-content: center; align-items: center;"
            class="mb-3"
        >
            <v-avatar
                class="mr-1"
                color="primary"
                size="44"
            >
                <span class="white;text text-20">
                    {{ item.relationships.likes.meta.total }}
                </span>
            </v-avatar>
            {{ $tc('likes.likesType', item.relationships.likes.meta.total) }}
        </div>
        <v-divider />

        <v-row>
            <v-col
                class="col-auto pl-6"
            >
                <purpose-date
                    :purpose="item"
                    :show-owner="showOwner"
                />
            </v-col>

            <v-spacer />
            <v-col
                v-if="!isOnlyView"
                class="mr-4 my-0"
                style="display: flex; justify-content: flex-end"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :size="$vuetify.breakpoint.smAndUp ? '35px' : '28px'"
                            class="mr-2"
                            v-bind="attrs"
                            @click="editItem(item)"
                            v-on="on"
                        >
                            mdi-file-document-edit
                        </v-icon>
                    </template>

                    <span>{{ $t('common.edit') }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :size="$vuetify.breakpoint.smAndUp ? '35px' : '28px'"
                            v-bind="attrs"
                            @click="deleteItem(item)"
                            v-on="on"
                        >
                            mdi-delete-forever
                        </v-icon>
                    </template>

                    <span>{{ $t('common.delete') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <confirm-dialog
            ref="confirm"
        />
    </v-card>
</template>

<script>
import ConfirmDialog from "@/components/common/ConfirmDialog";
import purposeText from "@/components/purpose/purposeText";
import purposeDate from "@/components/purpose/purposeDate";
import axios from "@/plugins/axios";

import typesRecognitions from '@/data/typesRecognitions'
import { mapGetters } from "vuex";

export default {
    name: "Recognition",
    components: { ConfirmDialog, purposeText, purposeDate },
    props: {
        item: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        },
        isOnlyView: {
            type: Boolean,
            default: false
        },
        showOwner: {
            type: Boolean,
            default: false
        },
        showRecognizedPersonAndType: {
            type: Boolean,
            default: true
        },
        showLikes: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            urlMain: '/purposes'
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        typeRecognition() {
            if (this.item) {
                const type = typesRecognitions.find(t => t.id == this.item.attributes.type_recognition);
                if (type) { return type[`name_${this.getLang}`]; }
            }
            return '...';
        },
        recognizedUser() {
            return (this.item?.userData?.attributes?.name ?? "") + " " + (this.item?.userData?.attributes?.lastname ?? "");
        }
    },
    async mounted() {

    },
    methods: {
        editItem(item) {
            this.$router.push({ name: 'RecognitionCreate', params: { item: JSON.stringify(item) } });
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('recognitions.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'));

                    this.$emit('update:recognitions');
                }
            } catch (error) {
                /* if (error.response) {
        console.log(error.response.data.error) // "invalid_credentials"
        console.log(error.response.status)
        console.log(error.response.headers)
    } */
            }
            this.$store.dispatch('loading/end')
        }
    }
}
</script>

<style scoped>

</style>
